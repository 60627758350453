@import "styles/variables.scss";

a {
  color: currentColor;
  cursor: pointer;
  text-decoration: none;
}

.wallpaper {
  display: block;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
}

.title-job, 
.title-2,
.title-3{
  font-family: "Roboto", "Raleway", sans-serif;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.title-job, 
.title-2,
.title-3{
  color: $primary;
  margin-bottom: 0.5rem;
}

h1::selection,
p::selection,
span::selection {
  color: $primary;
  background-color: $background-main;
}

.section-header {
  font-size: 2.25rem;
  margin-bottom: 0.5rem;
}

.hr,
.project-hr {
  border: none;
  content: "";
  width: 12rem;
  margin-left: 0;
}

.gallery-card,
.project-card,
.project-image {
  border-radius: 1.5rem 0 1.5rem 0;
}

.content-section,
.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.social,
.languages {
  display: flex;
}

.social li,
.languages li {
  margin-right: 0.5rem;
  text-align: center;
}

/* Fixes IE11 bug where these sections overflow the parent container */
.project-card,
.gallery-description {
  max-width: 100%;
}



/* 3. Specific */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.content-section {
  height: 100%; /* Removes unnecessary space below the containers content in IE11 */
  width: 80%;
  max-width: 1920px;
  margin: 8rem 4rem 4rem 4rem;
}

.section-header {
  border-bottom: $primary 2px solid;
  color: $primary;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  height: 100%; /* Removes unnecessary space from the gallery div in IE11 */
  justify-content: space-between;
  width: 100%;
  margin: 4rem;
}

.gallery-card{
  display: flex;
  align-items: center;
  width: 22%;
  height: auto;
  background-color: white;
  padding: 0.5rem;
  margin: 1.5rem 0;
}

.gallery-image {
  display: flex;
  width: 100%; /* Prevents images overflowing the parent div in IE11 */
}

.float-side {
  float: right;
}

.name {
  line-height: 1.125;
}

.project-image {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  margin-left: 5px;
}

.title-3 {
  font-size: 0.70rem;
}

.project-hr {
  background-color: $background-secondary;
  height: 2px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.link-text {
  padding-left: 0.2rem;
}

.link {
  color: $primary;
}

.link:hover {
  text-decoration: underline;
}

.hr {
  background-color: $primary;
  height: 1px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

code {
  font-size: 87.5%;
  font-family: "Roboto Mono", monospace;
  color: $pink;
  word-break: break-word;
}

/* Specifc Gallery card background colours */
.sparked-logo {
  background-color: #191919;
}

.jj-pizza-logo {
  background-color: #DF4337;
}

.anchor-logo {
  background-color: #110C01;
}

/* Responsiveness */
@media screen and (max-width: 1000px) {
  .float-side {
      width: 45%;
  }
}

@media screen and (max-width: 799px) {
  .hero-content {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5rem 3rem;
  }

  .side {
      margin-bottom: 9rem;
      width: 50%;
  }

  .float-side {
      width: 30%;
  }

  .gallery-card {
      width: 46%;
      margin: 0.5rem;
  }
}
@media screen and (max-width: 700px) {
  .side {
      margin-bottom: 3rem;
      width: 60%;
  }
  .gallery-card {
      width: auto;
  }
}

@media screen and (max-width: 600px) {
  .side {
      margin-bottom: 3rem;
      width: 70%;
  }

  .float-side {
      float: none;
      margin: 10px 0;
      width: auto;
  }

  .project-image {
      margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .side {
      margin-bottom: 3rem;
      width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .side {
      margin-bottom: 3rem;
      width: 100%;
  }
}

@media screen and (min-width: 800px){
  .hero-content {
      align-items: center;
      justify-content: space-around;
      justify-content: space-evenly;
      padding: 2rem 4rem;
  }
  .side {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 4rem;
      order: 2;
      width: 20rem;
  }
  .about {
      flex-grow: 1;
      flex-shrink: 1;
  }
}