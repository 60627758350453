@import '../../../styles/variables.scss';

.picture-shadow,
.picture-image {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}

.picture {
    padding-top: 100%;
    position: relative;
    width: 100%;
    margin: 0;

    .picture-shadow {
        border-radius: 290486px;
        background-image: radial-gradient(#000 0%, rgba(0, 0, 0, 0) 70%);
        position: absolute;
        top: 10%;
    }
    
    .picture-image {
        border-radius: 290486px;
        position: absolute;
    }
}