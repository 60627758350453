@import '../../styles/variables.scss';

.hero-content {
    display: flex;
    position: relative;
    min-height: 85vh;
    max-width: 1920px;
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

    .name {
        font-size: 2.25rem;
        margin-bottom: 0.5rem;
    }

    .description {
        font-size: 1.2rem;
    }

    .title-job {
        font-size: 0.90rem;
    }
    
    .title-2 {
        font-size: 0.80rem;
    }

    .text-spacing {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }

    .scroll-down {
        position: absolute;
        left: 50%;
        background-color: transparent;
        bottom: 20px;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        width: 26px;
        height: 26px;
    }
}

.contact-button {
    font-family: "Roboto", "Raleway", sans-serif;
    width: 116px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    align-items: center;
    border-radius: 10px 0 10px 0;
    border: 2px solid $primary;
    box-shadow: inset 0 0 1em rgba(255, 186, 73, 0.3), 0 0 1em rgba(255, 186, 73, 0.3);
    color: $primary;
    display: flex;
    font-size: 0.8rem;
    font-weight: bold;
    justify-content: center;
    line-height: 1;
    padding: 0.8em 1.6em;
    text-shadow: none;
    transition-property: box-shadow, transform;
    user-select: none;
    vertical-align: top;
    white-space: nowrap;
    will-change: box-shadow, transform;
    transform-origin: center;
    transition-duration: 100ms;

    span {
        padding-left: 10px;
    }
}
  
.contact-button:hover {
    box-shadow: inset 0 0 0 rgba(255, 186, 73, 0.3), 0 0 1.5em rgba(255, 186, 73, 0.5);
    background-color: $primary;
    color: $background-main;
    transform: scale(1.02) translateY(-4px);
}
  
.contact-button:active {
    box-shadow: inset 0 0 0 rgba(255, 186, 73, 0.3), 0 0 1.5em rgba(255, 186, 73, 0.7);
    transform: scale(0.98) translateY(-2px);
}