
.footer{
    margin: 4rem 1rem;

    .footer-text {
        margin: 0.3rem 0;
        font-size: 0.9rem;
        text-align: center;
    }

    .hr {
        margin: 1.5rem auto;
    }
}